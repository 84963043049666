.colorprincipal {
    background-color: rgb(35,35,35);
}

.colorsecundario{
  background-color: rgb(85, 85, 85);
}

.letraborde {
  color: rgb(35,35,35);
  text-shadow: 1px  0px 0px white,
               0px  1px 0px white,
              -1px  0px 0px white,
               0px -1px 0px white;
}

.letraborde2 {
  color: rgb(0, 0, 0);
  text-shadow: 1px  0px 0px white,
               0px  1px 0px white,
              -1px  0px 0px white,
               0px -1px 0px white;
}

.letraborde3 {
  color: rgb(30,35,22);
  text-shadow: 1px  0px 0px white,
               0px  1px 0px white,
              -1px  0px 0px white,
               0px -1px 0px white;
}

.letraborde4 {
  color: rgb(239,242,222);
  text-shadow: 1px  0px 0px black,
               0px  1px 0px black,
              -1px  0px 0px black,
               0px -1px 0px black;
}

.contenedor{
  position: relative;
  display: inline-block;
  text-align: center;
}

.centrado3{
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centrado4{
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centrado5{
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centrado6{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.botoncentrado{
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 30px;
}

.botoncentrado2{
  position: absolute;
  width: 150px;
  height: 30px;
}